import * as types from './Types'

const initailState = {
    CarBrand: [],
    CarModel: [],
    CarFuel: [],
    CarVariant: [],
    CarRTO: [],
    StateName: [],
    CityName: [],
    Relation: [],
    Occupation: [],
    Insurer: [],
    PreviousInsurer: [],
    Financers: [],
    quotesData: [],
    makeData: {},
    modelData: {},
    fuelData: {},
    variantData: {},
    rtoData: {},
    yearData: {},
    ownerData: {},
    PersonalInfo: {},
    VehicleInfo: {},
    AddInfo: {},
    vehicleAddInfo: {},
    addRiders: {},
    proposalData: [],
    allProposalData: [],
    getQuotesBasedOn: [],
    AllQuotesData: [],
    respondQuotesData: [],
    cashlessGarage: [],
    getGodigitKyc: [],
    isKycDone: [],
    loading: true
}

export const CarReducers = (state = initailState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_ALL_MAKES:
            return {
                ...state,
                CarBrand: payload,
                loading: false
            }
        case types.GET_ALL_MODEL:
            return {
                ...state,
                CarModel: payload,
                loading: false
            }
        case types.GET_ALL_FUEL:
            return {
                ...state,
                CarFuel: payload,
                loading: false
            }
        case types.GET_ALL_VARIANT:
            return {
                ...state,
                CarVariant: payload,
                loading: false
            }
        case types.GET_ALL_RTO:
            return {
                ...state,
                CarRTO: payload,
                loading: false
            }
        case types.GET_ALL_STATE:
            return {
                ...state,
                StateName: payload,
                loading: false
            }
        case types.GET_ALL_CITY:
            return {
                ...state,
                CityName: payload,
                loading: false
            }
        case types.GET_ALL_RELATION:
            return {
                ...state,
                Relation: payload,
                loading: false
            }
        case types.GET_ALL_OCCUPATION:
            return {
                ...state,
                Occupation: payload,
                loading: false
            }
        case types.GET_INSURER:
            return {
                ...state,
                Insurer: payload,
                loading: false
            }
        case types.GET_PREVIOUS_INSURER:
            return {
                ...state,
                PreviousInsurer: payload,
                loading: false
            }
        case types.GET_ALL_FINANCER:
            return {
                ...state,
                Financers: payload,
                loading: false
            }
        // ******************************************************************  MakeModal Store
        case types.ADD_MAKE:
            return {
                ...state,
                makeData: payload,
                loading: false
            }
        case types.ADD_MODEL:
            return {
                ...state,
                modelData: payload,
                loading: false
            }
        case types.ADD_FUEL:
            return {
                ...state,
                fuelData: payload,
                loading: false
            }
        case types.ADD_VARIANT:
            return {
                ...state,
                variantData: payload,
                loading: false
            }
        case types.ADD_RTO:
            return {
                ...state,
                rtoData: payload,
                loading: false
            }
        case types.ADD_YEAR:
            return {
                ...state,
                yearData: payload,
                loading: false
            }

        // ******************************************************************  Quotes
        case types.GET_QUOTES:
            return {
                ...state,
                quotesData: payload,
                loading: false
            }
        case types.GET_QUOTES_BASED_LEAD:
            return {
                ...state,
                getQuotesBasedOn: payload,
                loading: false
            }
        case types.RESET_FORM_DATA:
            return {
                ...state,
                quotesData: initailState
            }

        case types.ADD_ALLQUOTES:
            return {
                ...state,
                AllQuotesData: payload,
                loading: true
            }
        // case types.GET_ALL_ACTIVE_INSURER: return {
        //     ...state,
        //     allActiveInsurer: payload,
        //     loading: true
        // }
        // ******************************************************************  Proposal 
        case types.OWNWE_DETAIL:
            return {
                ...state,
                ownerData: payload,
                loading: false
            }
        case types.PERSONAL_DETAILS:
            return {
                ...state,
                PersonalInfo: payload,
                loading: false
            }
        case types.VEHICLE_INFO:
            return {
                ...state,
                VehicleInfo: payload,
                loading: false
            }
        case types.ADDRESS_DETAIL:
            return {
                ...state,
                AddInfo: payload,
                loading: false
            }
        case types.VEHICLE_ADDRESS_DETAIL:
            return {
                ...state,
                vehicleAddInfo: payload,
                loading: false
            }
        case types.GET_PROPOSAL:
            return {
                ...state,
                proposalData: payload,
                loading: false
            }
        case types.GET_PROPOSAL_PROPOSALID:
            return {
                ...state,
                allProposalData: payload,
                loading: false
            }
        case types.ADD_RIDER:
            return {
                ...state,
                addRiders: payload,
                loading: false
            }
        // ******************************************************************  Cashless Garages
        case types.GET_CASHLESS_GARAGES:
            return {
                ...state,
                cashlessGarage: payload,
                loading: true
            }

        // ******************************************************************  Godigit Kyc
        case types.GET_KYC_DETAIL:
            return {
                ...state,
                getGodigitKyc: payload,
                loading: true
            }
        // ******************************************************************  IsKYC Done Godigit
        case types.GET_GODIGIT_KYC_CONTROLLER:
            return {
                ...state,
                isKycDone: payload,
                loading: true
            }
        // ******************************************************************  other 
        case types.RESET_JOURNEY_FORM_DATA:
            return {
                ...state,
                makeData: initailState,
                modelData: initailState,
                fuelData: initailState,
                variantData: initailState,
                rtoData: initailState,
                yearData: initailState,
                AllQuotesData: initailState,
                quotesData: initailState,
                isKycDone: initailState

            }
        default:
            return state
    }
}

export default CarReducers