import * as types from './UsersTypes'

const initialState = {
    getButtonPrivilege: {},
    getRoleBasedModuleAndSubModulePrivilege: [],
    allActiveProducts: [],
    allActiveRoles: [],
    getTeamRoleBased: [],
    getKeyManager: {},
    getAllRegion: [],
    getSingleRegion: {},
    getAllBranch: [],
    getAllQualification: [],
    getSingleBranch: {},
    getAllUsers: [],
    getTotalCountedUserStatus: {},
    addDataPersonalInfo: {},
    addDataAddressInfo: {},
    addDataDocumentInfo: {},
    addDataPrivillegeInfo: {},
    addDataPaymentnfo: {},
    storePidArray: {},
    GetAllDept: [],
    loading: true,
    errorMSG: ''
}
export const usersReducers = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_ALL_PRODUCTS:
            return {
                ...state,
                allActiveProducts: payload,
                loading: false
            }
        case types.GET_BUTTON_PRIVILEGE:
            return {
                ...state,
                getButtonPrivilege: payload,
                loading: false
            }
        case types.GET_ALL_ROLES:
            return {
                ...state,
                allActiveRoles: payload,
                loading: false
            }
        case types.GET_ROLEBASED_PRIV:
            return {
                ...state,
                getRoleBasedModuleAndSubModulePrivilege: payload,
                loading: false
            }
        case types.GET_TEAM_ROLE_BASED:
            return {
                ...state,
                getTeamRoleBased: payload,
                loading: false
            }
        case types.GET_ALL_MANAGER:
            return {
                ...state,
                getKeyManager: payload,
                loading: false
            }
        case types.GET_ALL_REGION:
            return {
                ...state,
                getAllRegion: payload,
                loading: false
            }
        case types.GET_SINGLE_REGION:
            return {
                ...state,
                getSingleRegion: payload,
                loading: false
            }
        case types.GET_ALL_BRANCH:
            return {
                ...state,
                getAllBranch: payload,
                loading: false
            }
        case types.GET_ALL_QUALIFICATION:
            return {
                ...state,
                getAllQualification: payload,
                loading: false
            }
        case types.GET_SINGLE_BRANCH:
            return {
                ...state,
                getSingleBranch: payload,
                loading: false
            }
        case types.GET_ALL_USERS:
            return {
                ...state,
                getAllUsers: payload,
                loading: false
            }
        case types.GET_TOTAL_COUNTED_USERS_STATUS:
            return {
                ...state,
                getTotalCountedUserStatus: payload,
                loading: false
            }
        case types.ADD_PERSONALINFO:
            return {
                ...state,
                loading: false
            }
        case types.ADD_NEW_USER:
            return {
                ...state,
                loading: false
            }
        case types.ADD_DATA_PERSONALINFO:
            return {
                ...state,
                addDataPersonalInfo: payload,
                loading: false
            }
        case types.ADD_DATA_ADDRESSINFO:
            return {
                ...state,
                addDataAddressInfo: payload,
                loading: false
            }
        case types.ADD_DATA_DOCUMENTINFO:
            return {
                ...state,
                addDataDocumentInfo: payload,
                loading: false
            }
        case types.ADD_DATA_PRIVILLEGEINFO:
            return {
                ...state,
                addDataPrivillegeInfo: payload,
                loading: false
            }
        case types.ADD_DATA_PAYMENTINFO:
            return {
                ...state,
                addDataPaymentnfo: payload,
                loading: false
            }
        case types.GET_PID_ARRAY: return {
            ...state,
            storePidArray: payload,
            loading: false
        }
        case types.GET_ACTIVE_DEPT: return {
            ...state,
            GetAllDept: payload,
            loading: false
        }
        case types.RESER_STORE_DATA: return {
            ...state,
            addDataPersonalInfo: initialState,
            addDataAddressInfo: initialState,
            addDataDocumentInfo: initialState
          
        }

        // case types.GET_EMP:
        //    return{
        //         ...state,
        //         emp: payload,
        //         loading: false,
        //     };
        // case types.GET_URN_NO:
        //    return{
        //         ...state,
        //         urn_no: payload,
        //         loading: false,
        //     };
        // case types.SERVICE_ERROR:
        //     return{
        //             ...state,
        //             errorMSG: payload,
        //             loading: false,
        //         };
        // case types.REMOVE_SERVICE_ERROR:
        //     return{
        //             ...state,
        //             errorMSG: '',
        //             loading: false,
        //         };
        default:
            return state
    }
}
export default usersReducers