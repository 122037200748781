import * as types from './Type'

const initailState = {
    AllLeads: [],
    getProposl: {},
    loading: true
}
export const LeadReducers = (state = initailState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_ALL_LEADS: return {
            ...state,
            AllLeads: payload,
            loading: false
        }
        case types.GET_PROPOSAL_LEADID_BASED: return {
            ...state,
            getProposl: payload,
            loading: false
        }
        default: return state
    }
}
export default LeadReducers