import * as types from './Types'
const initialState = {
    ModuleAccess: [],
    userDetail: {},

    personalInfo: {},
    aadhaarInfo: {},
    panCardInfo: {},
    addressInfo: {},

    loadEducation: [],
    loadAllCity: [],
    loading: true
}

const loginReducers = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.MENU_ACCESS:
            return {
                ...state,
                ModuleAccess: payload,
                loading: false
            }
        case types.GET_USER_DETAIL_BASED_ID:
            return {
                ...state,
                userDetail: payload,
                loading: false
            }
        // ***************************** POS Info
        case types.PERSONAL_INFO: return {
            ...state,
            personalInfo: payload,
            loading: false
        }

        case types.PAN_INFO: return {
            ...state,
            panCardInfo: payload,
            loading: false
        }
        case types.AADHAAR_INFO: return {
            ...state,
            aadhaarInfo: payload,
            loading: false
        }
        case types.ADDRESS_INFO: return {
            ...state,
            addressInfo: payload,
            loading: false
        }

        case types.GET_ALL_CITY: return {
            ...state,
            loadAllCity: payload,
            loading: false
        }
        case types.GET_EDUCATION: return {
            ...state,
            loadEducation: payload,
            loading: false
        }
        default:
            return state
    }

}
export default loginReducers