import * as types from './Types'

const initailState = {
    products: [],
    examSchedule: {},
    loading: true
}

export const DashboardReducers = (state = initailState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_ALL_PRODUCTS:
            return {
                ...state,
                products: payload,
                loading: false
            }
            case types.GET_EXAM_SCHEDULE:
            return {
                ...state,
                examSchedule: payload,
                loading: false
            }
        default:
            return state
    }
}

export default DashboardReducers